<template>
	<div>
		123
		{{ path }}
	</div>

</template>

<script>
export default {
	name: "index",
	data(){
		return {
			path:{}
		}
	},
	mounted() {
		console.log(this.$route)
		this.path = this.$route.path;
	}
}
</script>

<style scoped>

</style>